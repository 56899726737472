<template>
  <v-row  class="ml-1 pa-6">
    <v-col cols="12">
      <v-row >
        <v-col cols="12">
          <h2 class="font-weight-bold headline hidden-sm-and-down">정산받을 계좌 및<br>회원의 결제를 관리할 수 있습니다.</h2>
        </v-col>
        <v-col cols="12">
          <div class="body-1 font-weight-bold mt-6 mb-2">결제기간</div>
          <v-row >
            <v-col cols="12" md="6">
              <v-btn-toggle v-model="duration" mandatory>
                <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px;" @click="updateDate(0, 0)">
                  전체
                </v-btn>
                <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px;" @click="updateDate(0, 2)">
                  2주
                </v-btn>
                <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px;" @click="updateDate(1, 0)">
                  1개월
                </v-btn>
                <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px;" @click="updateDate(3, 0)">
                  3개월
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-menu ref="menuStart" :close-on-content-click="false" v-model="menuStart" :nudge-right="40"
                        lazy transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-on="on" v-model="startFormatted" prepend-icon="event"
                                  @blur="startDate = parseDate(startFormatted)" style="max-width: 140px"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" locale="ko-kr" :max="endDate" no-title scrollable @input="menuStart = false">
                  </v-date-picker>
                </v-menu>
                <div class="pa-4 pt-6">-</div>
                <v-menu :close-on-content-click="false" v-model="menuEnd" :nudge-right="40"
                        lazy transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-on="on" v-model="endFormatted" prepend-icon="event"
                                  @blur="endDate = parseDate(endFormatted)" style="max-width: 140px"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" locale="ko-kr" :min="startDate" no-title scrollable @input="menuEnd = false">
                  </v-date-picker>
                </v-menu>
              </v-row>
            </v-col>
          </v-row >
        </v-col>
        <v-col class="pl-0 mb-2" cols="7" >
          <div class="body-1 font-weight-bold mt-6 mb-2">결제 내역</div>
          <v-select :items="items"
                    item-text="name"
                    item-value="id"
                    :value="companyId"
                    height="40px"
                    :hide-details="true"
                    single-line
                    @change="changeCompany">
            <template slot="selection" slot-scope="data">
              <span class="body-1">{{ data.item.name }}</span>
            </template>
            <template slot="item" slot-scope="data">
              <span class="body-1">{{ data.item.name }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col class="pl-0" cols="12">
          <v-data-table v-model="selected" :headers="[{ text: '등급∙회원명', sortable: false},
                          { text: '결제 금액', width: '80px', sortable: false},
                          { text: '수수료', width: '80px', sortable: false},
                          { text: '정산예정금액', width: '80px', sortable: false},
                          { text: '결제상태', width: '80px', sortable: false},
                          { text: '정산일', width: '80px', sortable: false},
                          { text: '환불', width: '72px', sortable: false}]"
                        :items="filtered" show-select
                        item-key="P_TID" mobile-breakpoint="0"
                        style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12);">
            <template v-slot:item="props">
              <tr style="font-size: 13px">
                <td class="pl-4" style="min-width: 40px">
                  <v-checkbox :disabled="((((props.item.contractData || {}).deliveryHistory || [])[((props.item.contractData || {}).deliveryHistory || []).length - 1] || {}).state || 0) === 4 ||
                              props.item.status == 10"
                              @click.native.stop.prevent v-model="props.selected" primary hide-details class="pl-0"
                  ></v-checkbox>
                </td>
                <td class="pa-0" style="min-width: 112px">
                  <v-icon size="20" :color="props.item.level" class="mr-2">mdi-hexagon</v-icon>
                  <span>{{ props.item.username }}</span><br>
                  <span class="ml-6" :class="props.item.platform === 'insta' ? 'pink--text text--darken-2': 'green--text'">{{ props.item.platform === 'insta' ? '인스타' : '네이버'}}</span>
                </td>
                <td class="pa-0">
                  <span>{{ props.item.price | numFormat }}원</span><br>
                  <span>신용카드</span>
                </td>
                <td class="pa-0">
                  <span>-{{ Math.round(props.item.price * 0.024) | numFormat }}원</span><br>
                  <span style="color: #484848">신용카드 2.4%</span>
                </td>
                <td class="pa-0 french-blue--text text-center">{{ (props.item.status == 10) ? 0 : (props.item.price - Math.round(props.item.price * 0.024)) | numFormat }}원</td>
                <td class="pa-0">
                  <span>{{(props.item.status == 10) ? '결제취소' : '결제완료'}}</span><br>
                  <span>{{ new Date(props.item.paidAt).toLocaleDateString() }}</span>
                </td>
                <td>
                  {{props.item.settlementDate ? $moment(props.item.settlementDate, 'YYYYMMDD').toDate().toLocaleDateString() : '-'}}
                </td>
                <td>
                  <v-icon v-if="((((props.item.contractData || {}).deliveryHistory || [])[((props.item.contractData || {}).deliveryHistory || []).length - 1] || {}).state || 0) != 4 &&
                          props.item.status != 10"
                          size="20" color="rouge" @click="selected.push(props.item);showRefund=true">mdi-credit-card-refund</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:footer="">
              <td v-if="selected[0]" :colspan="7" class="pa-1 text-right">
                <v-row class="mx-0" >
                  <v-col cols="9" class="mt-4 text-left">
                    <span class="rouge--text font-weight-bold">총 {{selected.length}}건 선택</span>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="primary" @click="showRefund=true" style="width:120px">
                      환불 처리
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-col class="text-right pa-0 ma-0" >
        <download-excel
          :data   = "filtered"
          :fields = "json_fields"
          name    = "history.xls">
          <v-btn color="#61c9a8" class="ma-0 white--text" style="min-width:150px">엑셀파일 다운로드</v-btn>
        </download-excel>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="showRefund" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showRefund=false">close</v-icon><br>
                <h2 class="mt-2 font-weight-bold headline">환불 처리</h2>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row >
                <v-col>
                  <div>아래 회원의 결제건에 대해 환불을 진행합니다.</div>
                </v-col>
                <v-col cols="12" class="mt-6">
                  <div class="font-weight-bold mb-4">환불 내역</div>
                  <v-data-table :headers="[{ text: '광고명', sortable: false},
                      { text: '회원명', sortable: false},
                      { text: '결제금액', sortable: false}]"
                                :items="selected" :items-per-page="5" mobile-breakpoint="0"
                                style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
                    <template v-slot:item="props">
                      <tr style="font-size: 13px">
                        <td class="pr-2 pl-0" style="min-width: 150px">{{props.item.platform === 'insta' ? '인스타그램' : '네이버블로그'}}/{{props.item.companyName}}</td>
                        <td class="px-1">{{ props.item.username }}</td>
                        <td class="px-1">{{ props.item.price }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="rouge" outlined flat @click.native="showRefund=false">취소</v-btn>
              <v-btn class="rouge white-two--text" flat @click="showRefund=false; refund()">확인</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import companyMixin from '@/components/shared/companyMixin'

  import JsonExcel from 'vue-json-excel'
  import Vue from 'vue'

  export default {
    name: "PaymentHistory.vue",
    mixins: [companyMixin],
    props: ['settlements'],
    components: {
      downloadExcel: JsonExcel
    },
    data() {
      return {
        showRefund: false,
        selected: [],
        duration: 2,
        menuStart: false,
        menuEnd: false,
        startDate: '',
        endDate: '',
        startFormatted: null,
        endFormatted: null,
        companyId: -1,
        json_fields: {
          '업데이트 일자': {
            callback: () => {
              return Vue.moment(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD HH:MM');
            }
          },
          '광고명': 'P_MNAME',
          '회원명': 'username',
          '등급': 'level',
          '채널명': 'platform',
          '결제수단': {
            callback: () => {
              return '신용카드';
            }
          },
          '결제금액': 'price',
          '수수료-신용카드': {
            callback: (value) => {
              return Math.round(value.price * 0.024);
            }
          },
          '정산예정금': {
            callback: (value) => {
              return (value.price - Math.round(value.price * 0.024));
            }
          },
          '결제상태': {
            callback: (value) => {
              let status = '결제중';
              if (value.status == 3) {
                status = '결제완료';
              }
              else if (value.status == 5) {
                status = '정산예정';
              }
              else if (value.status == 7) {
                status = '정산완료';
              }
              else if (value.status == 10) {
                status = '결제취소';
              }
              return status;
            }
          },
          '결제일자': {
            callback: (value) => {
              return Vue.moment(value.paidAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:MM');
            }
          },
          '취소일자': {
            callback: (value) => {
              return value.canceledAt ? Vue.moment(value.canceledAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:MM') : '';
            }
          }
        }
      }
    },
    computed: {
      items() {
        const items = [{id: -1, name: "전체광고"}, ...JSON.parse(JSON.stringify(this.companies))];
        return items;
      },
      filtered: function () {
        return (this.settlements || []).filter((settlement) => {
          let date = this.$moment(settlement.paidAt, 'YYYYMMDD').tz('Asia/Seoul').format('YYYY-MM-DD');
          let filter1 = this.$moment(date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]');
          let filter2 = settlement.cid == this.companyId;
          return (filter1 && (filter2 || this.companyId == -1) && settlement.status >= 3 && settlement.status <= 10);
        }) || [];
      }
    },
    watch: {
      startDate () {
        this.startFormatted = this.formatDate(this.startDate)
      },
      endDate () {
        this.endFormatted = this.formatDate(this.endDate)
      },
      settlements(value) {
        this.getInitData(value)
      }
    },
    methods: {
      formatDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('-');
        return `${year}. ${month}. ${day}`
      },
      parseDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('. ');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      updateDate (mon, week) {
        this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
        if(mon > 0 || week > 0) {
          this.startDate = this.$moment(this.endDate).subtract(mon, 'months').subtract(week, 'weeks').format('YYYY-MM-DD');
        }
        else {
          this.startDate = this.$moment().subtract(10, 'years').format('YYYY-MM-DD')
        }
      },
      async getInitData(settlements) {
        this.settlements = settlements;
        // this.filtered = settlements.filter((settlement) => {
        //   return(settlement.status == 5)
        // });
        // this.filtered = this._.orderBy(this.filtered, ['cid', 'paidAt'], ['desc', 'desc']);
      },
      changeCompany (id) {
        this.companyId = id;
      },
      refund() {
        Promise.all(this.selected.map((settlement) => {
          return this.axios.delete('/inicis', {
            tid: settlement.P_TID,
            mid: settlement.P_MID,
            msg: '가맹점 취소',
            cancelreason: ''
          });
        }))
      }
    },
    async created() {
      this.startDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');

      this.getInitData(this.settlements);

      this.startFormatted = this.formatDate(this.startDate);
      this.endFormatted = this.formatDate(this.endDate);

      this.updateDate(1, 0);
    }
  }
</script>

<style scoped>

</style>